
<template>
  <div class="body log-record">
    <el-form
      :model="form"
      :inline="true"
      ref="form"
      :rules="rules"
      label-width="80px"
      label-position="right"
      class="demo-form-inline"
    >
      <el-form-item label="选择时间：" prop="time">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button type="primary" size="small" :loading="searchLoading" @click="onSearch()"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" stripe border height="400px">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column label="操作人" prop="operationName" width="100"></el-table-column>
      <el-table-column label="操作时间" prop="createTime" width="160"></el-table-column>
      <el-table-column label="变更记录" prop="content" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryOperationPageByMenu
} from '@/api/lib/api.js'
import {
  getRangeDay,
  formatDate,
  checkTimeLimitDay,
} from "@/common/utils/index";

export default {
  props: {
    //编辑时暂存的车架号
    identifyCode: {
      type: String
    },
    rowData: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (value) {
        if (new Date() <= value[0] || new Date() <= value[1]) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      timeLimit: 60,
      form: {
        pageSize: 10,
        currentPage: 1,
        time: null,
      },//
      searchLoading:false,
      rules: {
        time: [{ validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 7;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30;
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
      total: 0,
    }
  },
  mounted () { this.getListByField(); },
  methods: {
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getListByField();
    },
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    getListByField () {
      this.$refs.form.validate((valid) => {        
        if (valid) {
          let data = { ...this.form };
          if (data.time) {
            data.operationDateStart = formatDate(this.form.time[0])
            data.operationDateEnd =
              formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59'
          } else {
            data.operationDateStart = null
            data.operationDateEnd = null
          }
          delete data.time
          data.dataId = this.rowData.id;
          data.identifyCode = this.identifyCode;//车辆管理菜单id
          queryOperationPageByMenu(data).then(res => {
            if (res.code === 1000) {
              this.tableData = res.data.list || [];
              this.total = res.data.total || 0;
            } else {
              this.tableData = [];
              this.total = 0;
            }
            this.searchLoading = false;
            console.log("--------", res);
          })
        }else{
          this.searchLoading = false;
        }
      })

    }
  },
  created () {
    
  }
}
</script>

<style lang="scss" scoped>
.log-record {
  @include themify() {
    /deep/.el-table:not(.no-blank) .el-table__empty-text {
      height: 60px;
    }
    /deep/.el-date-editor .el-range-separator {
      line-height: 24px;
    }
    /deep/.el-form {
      display: grid;
      grid-template-columns: 40% 25%;
      background: #f7f8f9;
      padding: 16px 16px 0;
    }
    /deep/.el-date-editor.el-input__inner {
      width: 240px;
    }
  }
}
</style>
